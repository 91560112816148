@font-face {
  font-family: 'Roboto';
  src: url('../../../../assets/fonts/OpenSans-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../../../assets/fonts/OpenSans-Bold.ttf');
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ReactTable-wrapper {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #fff;
  min-height: 200px;

  &.plain-table {
    min-height: auto;
    .ReactTable {
      border: none;
      .rt-thead {
        &.-header {
          border-bottom: none;
        }
      }

      .rt-tbody {
        .rt-tr-group {
          border-bottom: none;
        }
      }
    }
  }
}

.ReactTable {
  min-height: 195px;
  &,
  &-striped {
    .rt-resizable-header {
      color: #063e5a;
      text-align: left;
      font-family: 'Roboto';
      font-weight: bolder;
    }
    .rt-thead {
      flex: none;
      &.-headerGroups {
        display: none;
      }
      &.-header {
        border-bottom: solid 2px black;
        padding: '10px 0px 10px 20px';
        .rt-th {
          border: none;
          padding: 10px 0px 10px 20px;
        }
      }
    }

    .rt-tbody {
      flex: 1;
      font-family: 'Roboto';
      .rt-tr {
        align-items: center;
      }
      .rt-tr-group {
        border-bottom: solid 2px black;
        flex: none;
      }
      .rt-td {
        border: none;
      }
    }

    .rt-tr-group {
      background: white;
      border-bottom: solid 2px black;
      .-even {
        background-color: white;
      }
      .-odd {
        background-color: white;
      }
    }

    .rt-td {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      color: #696969;
      padding-left: 20px;
      border: none;
    }
  }
}
