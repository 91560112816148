.password-strength-meter {
  text-align: center;
  margin: 30px auto 0;

  &--progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;

    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 4px;
    }
    &::-webkit-progress-value {
      border-radius: 2px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    }
  }

  &--label {
    display: block;
    text-align: center;
    font-size: 1rem;
  }

  .strength-Weak::-webkit-progress-value {
    background-color: #e74c3d;
  }

  .strength-Medium::-webkit-progress-value {
    background-color: #f0c41a;
  }

  .strength-Strong::-webkit-progress-value {
    background-color: #a6e15e;
  }
}
